import { memo } from "react";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import { Logo } from "assets/images/cherrypickLogo";

const HeaderLayout = () => {
  const navigate = useNavigate();
  const url = window.location.href;

  return (
    <div className={styles["header-layout"]}>
      <div className={styles["header-layout-top-container"]}>
        <div className={styles["logo-wrapper"]} onClick={() => navigate("/")}>
          <Logo />
        </div>
        <button
          className={styles["contact-us-btn"]}
          onClick={() => navigate(url.includes("/recruit") ? "/contact-us-recruit" : "/contact-us")}
        >
          {url.includes("/recruit") ? "Hire with us" : "Contact Us"}
        </button>
      </div>
    </div>
  );
};

export default memo(HeaderLayout);
