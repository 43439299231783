import { useNavigate } from "react-router-dom";
import styles from "./HireWithUs.module.scss";
import { ArrRight } from "assets/images/white-arr-right";

const HireWithUs = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["hire-with-us-wrapper"]}>
      <p>
        Already have a team, but looking for a new member? We will cherry-pick a candidate for you
      </p>
      <button onClick={() => navigate("/contact-us-recruit")}>
        Hire with us <ArrRight />
      </button>
    </div>
  );
};

export default HireWithUs;
